import React from "react"
// import PropTypes from 'prop-types'
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"
import Content from "components/Content"
import { linkResolver } from "utils/linkResolver"

const StyledArticle = styled.article`
  background: ${(props) => props.theme.colors.gray_light};
  padding: 4rem 2rem;

  .inner {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    padding: 4rem 2rem;
    max-width: 96rem;
    margin: 0 auto;
  }

  h1 {
    margin: 0;
    font-size: 2.4rem;
    line-height: 1.4;
  }

  h2 {
    margin-top: 1.5em;
    font-size: 2.1rem;
    line-height: 1.4;
  }

  h3 {
    margin-top: 1.5em;
    font-size: 1.8rem;
    line-height: 1.3;
  }

  a {
    color: ${(props) => props.theme.colors.bilkablue};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 4rem 0;

    .inner {
      padding: 4rem;
    }

    h1 {
      margin: 0;
      font-size: 3.2rem;
      line-height: 1.4;
      max-width: 80%;
    }

     h2 {
      font-size: 2.6rem;
      max-width: 80%;
    }

    h3 {
      font-size: 2.2rem;
      max-width: 80%;
    }

    p, li {
      max-width: 80%;
    }
  `}
`

function Article({ data: { prismic, site }, ...props }) {
  const { article, mainPage, site_settings } = prismic
  if (!article) return null
  const { body } = mainPage
  const {
    meta_title,
    meta_description,
    content,
    _meta: { lang },
  } = article
  const { siteUrl, ...defaultMeta } = site.defaultMeta
  const uri = linkResolver(article._meta)

  const onPageNav = body
    .map((s) => {
      if (s.hasOwnProperty("primary") && s.primary.anchor_title) {
        return {
          anchor: s.primary.anchor_title,
        }
      }
      return
    })
    .filter(Boolean)

  return (
    <>
      <SEO
        lang={lang}
        slug={uri}
        title={meta_title}
        description={meta_description}
        defaultMeta={defaultMeta}
        hideFromGoogle
      />
      <Layout site_settings={site_settings} lang={lang} pageNav={onPageNav}>
        <StyledArticle>
          <div className="inner">
            <Content content={content} />
          </div>
        </StyledArticle>
      </Layout>
    </>
  )
}

export default Article

Article.defaultProps = {}

Article.propTypes = {}

export const query = graphql`
  query PrismicArticle($lang: String!, $uid: String!) {
    site {
      defaultMeta: siteMetadata {
        ...SiteMetaFragment
      }
    }
    prismic {
      site_settings(lang: $lang, uid: "site_settings") {
        copyright_information
      }
      article(lang: $lang, uid: $uid) {
        _meta {
          type
          lang
          uid
        }
        meta_title
        show_in_sitemap
        content
      }
      mainPage: page(lang: "da-dk", uid: "kitchenaid") {
        _meta {
          type
          lang
          uid
        }
        body {
          ... on PRISMIC_PageBodyParagraph___image {
            primary {
              use_anchor
              anchor_title
            }
          }
          ... on PRISMIC_PageBodyFeature_repeater {
            primary {
              use_anchor
              anchor_title
            }
          }
          ... on PRISMIC_PageBodyProduct_repeater {
            primary {
              use_anchor
              anchor_title
            }
          }
        }
      }
    }
  }
`
